<template>
    <q-card flat>
        <q-item>
            <q-item-section avatar>
                <q-avatar>
                    <q-icon
                        class="glossy"
                        color="primary"
                        size="30px"
                        name="account_box"
                    />
                </q-avatar>
            </q-item-section>

            <q-item-section>
                <q-item-label>日志管理</q-item-label>
                <q-item-label caption> 管理维护系统操作日志信息</q-item-label>
            </q-item-section>
            <q-item-section side>
                <div>
                    <q-btn-dropdown color="primary" label="清理日志">
                        <q-list>
                            <q-item
                                clickable
                                v-close-popup
                                @click="onClearLog(6)"
                            >
                                <q-item-section>
                                    <q-item-label>6个月前</q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item
                                clickable
                                v-close-popup
                                @click="onClearLog(3)"
                            >
                                <q-item-section>
                                    <q-item-label>3个月前</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                clickable
                                v-close-popup
                                @click="onClearLog(1)"
                            >
                                <q-item-section>
                                    <q-item-label>1个月前</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-separator />
                            <q-item
                                clickable
                                v-close-popup
                                @click="onClearLog(0)"
                            >
                                <q-item-section>
                                    <q-item-label>清空日志</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-btn-dropdown>
                </div>
            </q-item-section>
        </q-item>
        <q-separator />
        <q-card-section>
            <div class="row q-ma-xs q-col-gutter-x-md justify-start">
                <q-input
                    filled
                    class="col-2 q-col-gutter-sm"
                    dense
                    v-model="queryParm.DateStart"
                    label="开始日期"
                >
                    <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                                transition-show="scale"
                                transition-hide="scale"
                            >
                                <q-date
                                    v-model="queryParm.DateStart"
                                    mask="YYYY-MM-DD HH:mm:ss"
                                >
                                    <div class="row items-center justify-end">
                                        <q-btn
                                            v-close-popup
                                            label="Close"
                                            color="primary"
                                            flat
                                        />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>

                    <template v-slot:append>
                        <q-icon name="access_time" class="cursor-pointer">
                            <q-popup-proxy
                                transition-show="scale"
                                transition-hide="scale"
                            >
                                <q-time
                                    v-model="queryParm.DateStart"
                                    mask="YYYY-MM-DD HH:mm"
                                    format24h
                                >
                                    <div class="row items-center justify-end">
                                        <q-btn
                                            v-close-popup
                                            label="Close"
                                            color="primary"
                                            flat
                                        />
                                    </div>
                                </q-time>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <q-input
                    filled
                    class="col-2 q-col-gutter-sm"
                    dense
                    v-model="queryParm.DateEnd"
                    label="截止日期"
                >
                    <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                                transition-show="scale"
                                transition-hide="scale"
                            >
                                <q-date
                                    v-model="queryParm.DateEnd"
                                    mask="YYYY-MM-DD HH:mm:ss"
                                >
                                    <div class="row items-center justify-end">
                                        <q-btn
                                            v-close-popup
                                            label="Close"
                                            color="primary"
                                            flat
                                        />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                    <template v-slot:append>
                        <q-icon name="access_time" class="cursor-pointer">
                            <q-popup-proxy
                                transition-show="scale"
                                transition-hide="scale"
                            >
                                <q-time
                                    v-model="queryParm.DateEnd"
                                    mask="YYYY-MM-DD HH:mm"
                                    format24h
                                >
                                    <div class="row items-center justify-end">
                                        <q-btn
                                            v-close-popup
                                            label="Close"
                                            color="primary"
                                            flat
                                        />
                                    </div>
                                </q-time>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <q-select
                    filled
                    v-model="queryParm.LogType"
                    dense
                    class="col-2"
                    label="日志类型"
                    emit-value
                    map-options
                    :options="[
                        {
                            label: '全部',
                            value: '',
                        },
                        {
                            label: '用户登录',
                            value: '用户登录',
                        },
                        {
                            label: 'Http请求',
                            value: 'Http请求',
                        },
                        {
                            label: '请求异常',
                            value: '请求异常',
                        },
                        {
                            label: '数据库操作',
                            value: '数据库操作',
                        },
                        {
                            label: '数据库异常',
                            value: '数据库异常',
                        },
                    ]"
                ></q-select>
                <q-input
                    filled
                    v-model="queryParm.LogObject"
                    dense
                    class="col-2"
                    label="对象"
                ></q-input>

                <div>
                    <q-btn
                        color="primary"
                        label="查询"
                        @click="onLoadData(true)"
                        icon="search"
                    />
                </div>
            </div>
            <vxe-table
                size="mini"
                :data="LogList"
                border
                stripe
                resizable
                highlight-current-row
                highlight-hover-row
                :loading="Loading"
                :max-height="$store.getters.maxPageHeight - 190"
                show-overflow
            >
                <vxe-column
                    align="center"
                    type="seq"
                    title="序号"
                    width="60"
                    fixed="left"
                ></vxe-column>
                <vxe-column
                    title="日志时间"
                    width="160"
                    align="center"
                    fixed="left"
                >
                    <template #default="{ row }">
                        {{ formatTime(row) }}
                    </template>
                </vxe-column>
                <vxe-column
                    field="LogType"
                    title="日志类型"
                    width="88"
                    align="center"
                />
                <vxe-column
                    field="LogObject"
                    title="对象"
                    width="220"
                />
                <vxe-column
                    field="LogMethord"
                    title="方法"
                    width="260"
                />
                <vxe-column
                    field="Duration"
                    title="响应时间(ms)"
                    width="120"
                    align="right"
                >
                    <template #default="{ row }">
                        {{ formatMoney(row) }}
                    </template>
                </vxe-column>
                <vxe-column field="RequstParm" title="请求" width="360" />
                <vxe-column field="ResponseParm" title="应答" width="360" />
                <vxe-column field="LogContent" title="描述" width="360" />
                <vxe-column field="HospName" title="机构" width="180" />
                <vxe-column field="CUserName" title="用户" width="120" />
                <vxe-column field="HostIP" title="地址" width="120" />
                <vxe-column field="OSName" title="系统" width="120" />
                <vxe-column field="BrowserName" title="浏览器" width="120" />
                <vxe-column field="AppVersion" title="程序版本" width="120" />
            </vxe-table>
            <vxe-pager
                perfect
                v-model:current-page="queryParm.PageIndex"
                v-model:page-size="queryParm.PageSize"
                :total="queryParm.Count"
                :page-sizes="[
                    10,
                    100,
                    200,
                    500,
                    { label: '大量数据', value: 1000 },
                ]"
                :layouts="[
                    'PrevJump',
                    'PrevPage',
                    'Number',
                    'NextPage',
                    'NextJump',
                    'Sizes',
                    'FullJump',
                    'Total',
                ]"
                @page-change="onLoadData(false)"
            >
            </vxe-pager>
        </q-card-section>
    </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { toRaw } from "vue";

export default {
    computed: {
        ...mapState("SysLog", ["LogList", "Loading"]),
    },
    data() {
        return {
            queryParm: {
                DateStart: moment()
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm:ss"),
                DateEnd: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                HospID: null,
                LogType: "",
                LogObject: "",
                PageSize: 100,
                PageIndex: 1,
                Count: 0,
            },
        };
    },
    mounted() {
        this.onLoadData(true);
    },
    methods: {
        ...mapActions("SysLog", [
            "actLoadLogList",
            "actDeleteLogCollection",
            "actDeleteLogAll",
        ]),
        onLoadData(resetPage = false) {
            if (resetPage) this.queryParm.PageIndex = 1;
            this.actLoadLogList(this.queryParm).then((res) => {
                if (res.Code == 0) {
                    this.queryParm.Count = res.Count;
                }
            });
        },
        formatTime(val) {
            var row = toRaw(val);
            return moment(row.LogTime).format("YYYY-MM-DD HH:mm:ss");
        },
        formatMoney(val) {
            var row = toRaw(val);
            return Math.round(row.Duration);
        },
        onClearLog(e) {
            this.dlg.confirm(
                "是否确定要删除该时间范围的日志(*注:该操作删除的数据不能还原)"
            ).onOk(() => {
                if (e == 0)
                    this.actDeleteLogAll({}).then((res) => {
                        if (res.Code == 0) this.actLoadLogList(this.queryParm);
                    });
                else {
                    var parm = {
                        DateEnd: moment().subtract(e, "months"),
                    };
                    this.actDeleteLogCollection(parm).then((res) => {
                        if (res.Code == 0) this.actLoadLogList(this.queryParm);
                    });
                }
            });
        },
    },
};
</script>